import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      keepAlive:true
    }
  
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('../views/BrandView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/m',
    name: 'mHome',
    component: () => import('../views/mIndex.vue')
  },
  {
    path: '/mAbout',
    name: 'mAbout',
    component: () => import('../views/mAbout.vue')
  },
  {
    path: '/mBrand',
    name: 'mBrand',
    component: () => import('../views/mBrand.vue')
  },
  {
    path: '/mNews',
    name: 'mNews',
    component: () => import('../views/mNews.vue')
  },
  {
    path: '/mContact',
    name: 'mContact',
    component: () => import('../views/mContact.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
