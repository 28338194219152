// zh.js
export default {
    footer: {
        about:'关于我们',
        brand: '品牌',
        contact: '联系我们'
    },
    nav: {
        home:'首页',
        about:'关于我们',
        brand: '品牌',
        news: '新闻资讯',
        contact: '联系我们'
    },
    contact: {
        name:'姓名',
        email:'电子邮件',
        subject: '主题',
        message: '留言',
        messageBtn:'提交',
        nameRules:'请输入姓名',
        emailRules:'请输入邮箱',
        subjectRules:'请输入主题',
        messageRules:'请输入留言内容'
    },
    home: {
        about:'关于我们',
        recommend: '推荐',
        membership: '每月会员',
        office: '全球办事处',
        visitors :'每日在线访客',
        partners :'全球合作伙伴',
        movie: '最新作品'
    },
    news: {
        more:'查看更多'
    },
    mNav: {
        home:'首页',
        about:'关于我们',
        brand: '品牌',
        news: '新闻资讯',
        contact: '联系我们'
    },
}
