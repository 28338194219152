import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
export default createStore({
  state: {
    aboutInfo: {},
  },
  getters: {
  },
  mutations: {
    changAboutInfo(state,value) {
      state.aboutInfo = {
        ...state.aboutInfo,
        ...value
      }
    },

  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    paths: ["aboutInfo"]  //控制是否持久化
  })]
})
