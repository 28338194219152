// en.js
export default {
    footer: {
        about:'About',
        brand: 'Brand',
        contact: 'Contact'
    },
    nav: {
        home:'Home',
        about:'About',
        brand: 'Brand',
        news: 'News',
        contact: 'Contact'
    },
    contact: {
        name:'Name',
        email:'Email',
        subject: 'Subject',
        message: 'Message',
        messageBtn:'Submit',
        nameRules:'Please enter name',
        emailRules:'Please enter email',
        subjectRules:'Please enter subject',
        messageRules:'Please enter message'
    },
    home: {
        about:'About Us',
        recommend: 'Recommend',
        membership: 'Monthly Membership',
        office: 'Global Office',
        visitors :'Daily Online Visitors',
        partners :'Global Partners',
        movie: 'Movie Updates'
    },
    news: {
        more:'View More'
    },
    mNav: {
        home:'Home',
        about:'About',
        brand: 'Brand',
        news: 'News',
        contact: 'Contact'
    },
}
