<template>
  <NavBar msg="Welcome to Your Vue.js App" v-if="!isMobile()" />

  <!-- <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive"/>
  </router-view> -->

  <RouterView/>

  <FooterBar v-if="!isMobile()" />
</template>
<script setup>
import { onMounted, onActivated } from "vue";
import { useRouter } from "vue-router";
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import zh from "./locals/zh";

const router = useRouter();

const isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};

onMounted(() => {
  if (isMobile()) {
    console.log("mobile...");
    router.replace("/m");
  } else {
    console.log("pc...");
  }
  localStorage.getItem('lang') ? localStorage.getItem('lang') : localStorage.setItem('lang','zh')
});
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
a {
  color: #fff;
  list-style: none;
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 4px;
  position: absolute;
}
::-webkit-scrollbar-thumb {
  background: rgb(209, 208, 208);
}
::-webkit-scrollbar-track {
  background: #eee;
}
</style>
