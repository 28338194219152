<template>

    <div class="Footer">
     <div class="footer-top">
        <a href="https://t.me/tg69mediagroup">
        <img src="../assets/images/tg.png"/>
      </a>
      <a href="https://twitter.com/69media01">
        <img src="../assets/images/tt.png"/>
      </a>
     </div>
     <div class="footer-center">
     <a href="#/brand">{{$t('nav.brand')}}</a>
     <a href="#/about">{{$t('nav.about')}}</a>
     </div>
     <div class="footer-bottom">
     <el-button @click="goToContact">{{$t('nav.contact')}}</el-button>
     </div>
    </div>
</template>
<script setup>
import {useRouter } from "vue-router"

const router = useRouter()
const goToContact = ()=> {
    router.push("/contact")
}
</script>
<style lang="scss" scoped>
.Footer {
    background: #111;
    color: #fff;
    height: 250px;
    text-align: center;
    .footer-top {
        padding-top: 40px;
        img {
            width: 40px;
            height: auto;
            margin: 0 15px;
        }
    }
    .footer-center {
        margin-top: 40px;
        margin-bottom: 40px;
        a {
            margin: 0 10px;
        }
    }
}
</style>