<template>
    <div>
  <swiper
    :effect="'coverflow'"
    :modules="modules"
    :grabCursor="true"
    :rewind="true"
    :centeredSlides="true"
    :slidesPerView="'auto'"
    :initialSlide="2"
    :coverflowEffect="{
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }"
    :pagination="{ clickable: true }"
  >
    <swiper-slide v-for="(item, index) in movieList" :key="index">
      <div class="itemImg" @click="videoPlay(item)">
        <!-- <img :src="'http://localhost:3000' + item.cover" /> -->
        <!-- <el-image class="image" :src="'https://69media.top/web'+item.cover" /> -->
        <el-image class="image" :src="'https://69media.top/web'+item.cover" />
      </div>
    </swiper-slide>
  </swiper>

  <el-dialog v-model="visible" :show-close="true" style="width: 840px;background:#000" @close="closeVideo">

    <video v-if="movieVideo !=''" ref="homeVideo" :src="movieVideo" class="videoCover" controls="controls" controlslist="nodownload">您的浏览器不支持视频播放</video>
         <i v-else-if="movieVideo ==''" class="el-icon-plus avatar-uploader-icon"></i>
  </el-dialog>
</div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import SwiperClass, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { ElButton, ElDialog } from 'element-plus'
import { Edit, CircleCloseFilled } from "@element-plus/icons-vue";
// import swiper module styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
// more module style...

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const movieList = ref([]);
    const visible = ref(false)
    const movieVideo = ref('')
    const homeVideo = ref(null);
    onMounted(async () => {
      var res = await axios.get(
        "/webapi/Product/list?" +
          "lang=" +
          localStorage.getItem("lang") +
          "&" +
          "userAgent=" +
          1
      );
      if (res.data.ActionType === "OK") {
        movieList.value = res.data.data;
      }
    });
    const videoPlay = (val) => {
        movieVideo.value = "https://69media.top/web" +val.proVideo
        visible.value = true
    }
    const closeVideo = () => {
        console.log(homeVideo.value)
        homeVideo.value.pause()
    }
    return {
      movieList,
      videoPlay,
      visible,
      movieVideo,
      homeVideo,
      closeVideo,
      modules: [EffectCoverflow, Pagination],
    };
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
:deep(.swiper-pagination-bullet) {
  background: #9E9E9E;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.videoCover {
  width: 800px;
  height: 450px;
}
.image {
  width: 300px;
  height: 300px;
  background-size: cover;
}
</style>
