<template>
  <div class="Header-inner Header-inner--top">
    <div class="top-left">
      <a href="/">
        <img src="../assets/images/logo.png" />
      </a>
    </div>
    <div class="top-center">
      <div class="Header-nav">
        <div class="Header-nav-inner">
          <RouterLink to="/">{{ $t("nav.home") }}</RouterLink>
          <RouterLink to="/about">{{ $t("nav.about") }}</RouterLink>
          <RouterLink to="/brand">{{ $t("nav.brand") }}</RouterLink>
          <RouterLink to="/news">{{ $t("nav.news") }}</RouterLink>
          <RouterLink to="/contact">{{ $t("nav.contact") }}</RouterLink>
        </div>
      </div>
    </div>
    <div class="top-right">
      <div class="Header-nav-inner">
        <div class="menu-item" @click="changeLang('en')">English</div>
        <div class="menu-item" @click="changeLang('zh')">中文</div>
        <a
          href="https://t.me/tg69mediagroup"
          target="_blank"
          class="Header-nav-item"
        ></a>
        <a
          href="https://twitter.com/69media01"
          target="_blank"
          class="Header-nav-item"
        ></a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useI18n } from "vue-i18n";
const { locale } = useI18n();

const { t } = useI18n();
const router = useRouter()
const changeLang = (lang) => {
  console.log(router.currentRoute.value.path)
  locale.value = lang;
  localStorage.setItem("lang", lang); 
  if(['/','/news','/about','/brand'].includes(router.currentRoute.value.path)) {
    location.reload()
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.Header-inner {
  position: sticky;
  top: 0px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #000;
  color: white;
  max-width: 4000px !important;
  .top-left {
    flex: 0.8 1 0px;
    a {
      display: inline-block;

      img {
        width: 268px;
        height: auto;
      }
    }
  }
  .top-center {
    flex: 0 0 auto;
    .Header-nav {
      .Header-nav-inner {
        a {
          padding: 10px 20px;
          list-style-type: none;
          text-decoration: none;
          color: #fff;
          font-size: 20px;
        }
        .router-link-active {
          color: red;
        }
      }
    }
  }
  .top-right {
    flex: 1 1 0px;
    text-align: right;
    .Header-nav-inner {
      vertical-align: middle;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .menu-item {
        margin-right: 10px;
        cursor: pointer;
      }
      .Header-nav-item:nth-child(3) {
        background-image: url(../assets/images/tg.png);
        background-size: 28px;
        background-repeat: no-repeat;
        text-indent: -9999px;
        overflow: hidden;
      }
      .Header-nav-item:nth-child(4) {
        background-image: url(../assets/images/tt.png);
        background-size: 28px;
        background-repeat: no-repeat;
        text-indent: -9999px;
        overflow: hidden;
      }
      .Header-nav-item {
        display: inline-block;
        width: 30px;
        padding: 16px 0;
        margin: 0 10px;
      }
    }
  }
}
</style>
