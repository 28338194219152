<template>
  <div class="about">
    <div class="about_box">
      <video autoplay muted loop poster="../assets/images/web_bg.png" style="width: 100%;height:100%;object-fit:fill">
        <source src="../assets/images/home.mp4" />
      </video>
    </div>
    <div class="about-main">
      <div class="about_info" v-html="aboutContent">
       
      </div>
      <a class="about-btn" href="#/about">{{$t('home.about')}}</a>
    </div>
    <div class="num-box">
      
      <ul>
        
        <li>
          <span class="num-top">{{subscribers}}</span>
          <span class="num-bottom">{{$t('home.membership')}}</span>
        </li>
        <li>
          <span class="num-top">{{office}}</span>
          <span class="num-bottom">{{$t('home.office')}}</span>
        </li>
        <li>
          <span class="num-top">{{visitors}}</span>
          <span class="num-bottom">{{$t('home.visitors')}}</span>
        </li>
        <li>
          <span class="num-top">{{staff}}</span>
          <span class="num-bottom">{{$t('home.partners')}}</span>
        </li>
      </ul>
    </div>
    <div class="home-bottom">
      <div class="home-bootom-title"><h3>{{$t('home.recommend')}}</h3></div>
      <div class="brand-home">
        <ul >
            <li v-for="(item,index) in brandList" :key="index">
                <div class="app-item-box">
                    <a @click="jumpbrandUrl(item.BrandUrl)">
                    <!-- <img :src="'http://localhost:3000'+item.cover" /> -->
                    <!-- <img :src="'https://69media.top/web'+item.cover" /> -->
                    <el-image class="image" :src="'https://69media.top/web'+item.cover" />
                </a>
                </div>

            </li>
            
        </ul>
    </div>
    </div>
    
    <div class="home-bottom bg-black">
      <div class="home-bootom-title txt-white"><h3>{{$t('home.movie')}}</h3></div>
      <div class="brand-home movie-home">

        <movie></movie>
    </div>
    </div>
  </div>
</template>
<script setup>
import { ref,onMounted } from "vue";
import axios from 'axios'
import movie from "@/components/movie.vue";
import { useI18n } from 'vue-i18n'

const aboutList = ref([])
const aboutContent =ref()
const subscribers = ref()
const office = ref()
const visitors = ref()
const staff = ref()
const brandList = ref([])
const { t } = useI18n()




onMounted(async ()=> {
  getAboutInfo()
    var res = await axios.get("/webapi/Brand/list?"+'lang='+localStorage.getItem('lang')+'&'+ 'userAgent='+1)
    if(res.data.ActionType === "OK") {
        brandList.value = res.data.data
    }

    getHomeList()
})

const getHomeList = async() => {
  let res = await axios.get("/webapi/home/list")
  
  if(res.data.ActionType == "OK") {
   
    subscribers.value = res.data.data[0].subscribers || 30
    office.value = res.data.data[0].office || 4
    visitors.value = res.data.data[0].visitors || 1
    staff.value = res.data.data[0].staff || 100
    
  }
}
const getAboutInfo = async()=> {
  
  if(sessionStorage.getItem("about")) {
    aboutContent.value = sessionStorage.getItem("about")
    return false
  }else {
    var res = await axios.get("/webapi/about/list?"+'lang='+(localStorage.getItem('lang') || 'zh')+'&'+ 'userAgent='+1)
    if(res.data.ActionType === "OK") {
      aboutList.value = res.data.data
      res.data.data.forEach((val,index) => {
        if(val.userAgent == 1) {

          aboutContent.value = res.data.data[index].content
          // sessionStorage.setItem("about",aboutContent.value)
        }
      });
      
    }
  }
  
}
const jumpbrandUrl =(val) => {
    if(val) {
        // window.location.href=val
        window.open(val)
    }
}
</script>
<style lang="scss" scoped>
.about {
  overflow-x: hidden;
  .about_box {
    position: relative;
    p {
      color: #fff;
      font-size: 46px;
      font-weight: bold;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}
.about-main {
  text-align: center;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 450px;
  line-height: 32px;
  .about-btn {
    margin-top: 25px;
    background: #000;
    display: inline-block;
    width: 200px;
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-size: 22px;
    &:hover {
      background: #333;
    }
  }
}
.num-box {
  background: #000;
  color: #fff;
  padding: 8% 20%;
  ul {
    display: flex;
    justify-content: space-between;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      .num-top {
        font-size: 58px;
        font-weight: bold;
      }
      .num-bottom {
        font-size: 28px;
        margin-top: 15px;
      }
    }
  }
}
.home-bottom {
  padding: 80px 100px;
  .home-bootom-title {
    text-align: center;
    margin-bottom: 40px;
    h3 {
      font-size: 28px;
    }
  }
  .home-bottom-box {
    text-align: center;
    .more-btn {
    margin-top: 80px;
    margin-bottom: 20px;
    background: #000;
    display: inline-block;
    width: 200px;
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-size: 22px;
    &:hover {
      background: #333;
    }
  }
  }
  .brand-home {
    padding: 10px 0px;
    width: 1400px;
    margin: 0 auto;
    ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        li {
            width: 12.5%;
            height: auto;
            margin-bottom: 25px;
            text-align: center;
            cursor: pointer;
            .app-item-box {
                width: 100px;
                height: 100px;
                margin: auto;
                position: relative;
                .image {
                  width: 100px;
                  height: 100px;
                }
            }
            .movie-item-box {
                width: 300px;
                height: 180px;
                margin: auto;
                position: relative;
            }
            span {
                margin-top: 10px;
                display: inline-block;
            }
            img {
                width: 100%;
                height: 100%;
                border-radius: 20px;
                object-fit: cover;
            }
        }
    }
}
.movie-home {
    ul {
      li {
        width: 25%;
      }
    }
  }
}
.bg-black {
  background-color: #010101;
  .txt-white {
    color: #fff;
  }
}
</style>
